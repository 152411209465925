import {
  Avatar,
  Button,
  Card,
  Heading,
  Input,
  ListDownSVG,
  ListUpSVG,
  MagnifyingGlassSimpleSVG,
  OutlinkSVG,
  PlusSVG,
  RecordItem,
  Select,
  Tag,
  Typography,
  mq,
} from '@ensdomains/thorin';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CacheableComponent } from '../components/CacheableComponent/CacheableComponent';
import {
  colors,
  containsTwoDots,
  fetchENSAddress,
  generateRandomGradient,
  getContractInstance,
  getResolver,
} from '../common';
import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ethers } from 'ethers';
import CreateSubname from '../components/CreateSubname/CreateSubname';
import CreateRecordModal from '../components/CreateRecordModal/CreateRecordModal';
import WrapModal from '../components/WrapModal/WrapModal';

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    align-self: center;
    padding: ${theme.space['4']};
    padding-top: ${theme.space['18']};
    background-image: ${theme.colors.gradients.blue};
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 100% ${theme.space['28']};
    background-position-y: -1px;
    background-color: ${theme.colors.background};
    border-radius: ${theme.radii['2xLarge']};
    border: ${theme.space.px} solid ${theme.colors.border};
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ${theme.space['4']};
    flex-gap: ${theme.space['4']};

    ${mq.sm.min(css`
      padding: ${theme.space['6']};
      padding-top: ${theme.space['12']};
    `)}
  `
);

const StyledCard = styled(Card)(
  ({ theme }) => css`
    max-width: 780px;
    margin: 20px auto;
    flex-direction: column;
    gap: ${theme.space['4']};
    padding: 0;
  `
);

const Body = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.space[2]};
  `
);

const DirectionButton = styled.button(
  ({ theme }) => css`
    transition: all 0.15s ease-in-out;
    width: ${theme.space['10']};
    flex: 0 0 ${theme.space['10']};
    height: ${theme.space['10']};
    border: 1px solid ${theme.colors.border};
    border-radius: ${theme.space['2']};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      display: block;
      width: ${theme.space['3']};
      height: ${theme.space['3']};
      path {
        fill: ${theme.colors.accent};
      }
    }
    &:hover {
      background-color: ${theme.colors.border};
    }
  `
);

const PanelsContainer = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0px;
    :last-child {
      border-right: none;
    }
  `,
]);

const DetailStack = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
  `
);

const Name = styled(Typography)(
  () => css`
    overflow-wrap: anywhere;
  `
);

const TextStack = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ${theme.space['1']};
    flex-gap: ${theme.space['1']};
    width: 100%;
    overflow: hidden;
  `
);

const TableHeaderLeftControlsContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.space['2']};
    align-items: center;
    flex: 1;
  `
);

const TableHeaderLeadingLeft = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.space['2']};
    align-items: center;
    color: ${theme.colors.text};
    ${mq.sm.min(css`
      gap: ${theme.space['4']};
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    `)}
  `
);

const FirstItems = styled.div(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  `
);

const TableHeaderTrailing = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${mq.sm.min(css`
      flex: '2';
      width: ${theme.space['32']};
    `)}
  `
);

const TableHeader = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    border-bottom: 1px solid ${theme.colors.border};
    padding: ${theme.space['3']} ${theme.space['4']};
    gap: ${theme.space['2']};
    ${mq.sm.min(css`
      flex-direction: row;
      align-items: center;
      padding: ${theme.space['3']} ${theme.space['4.5']};
      gap: ${theme.space['6']};
    `)}
  `
);

const TableHeaderLeading = styled.div(
  () => css`
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

const ProfileInfoBox = styled(CacheableComponent)(
  ({ theme }) => css`
    background-color: ${theme.colors.background};
    border-radius: ${theme.radii['2xLarge']};
    border: ${theme.space.px} solid ${theme.colors.border};
    margin-top: 10px;
  `
);

const RecordsStack = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-gap: ${theme.space['2']};
    gap: ${theme.space['2']};
    padding: ${theme.space['4']};
  `
);

const TabWrapperWithButtons = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  `
);

const AddSubnamesCard = styled(Card)(
  ({ theme }) => css`
    padding: ${theme.space['6']};
    flex-direction: column;
    text-align: center;
    gap: ${theme.space['4']};
    justify-content: space-between;

    & > button {
      width: 100%;
    }

    ${mq.sm.min(css`
      flex-direction: row;
      text-align: left;
      & > button {
        width: min-content;
      }
    `)}
  `
);

const StyledAnchor = styled.a(
  ({ theme }) => css`
    padding-right: ${theme.space['4']};
    color: ${theme.colors.accent};
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.space['1']};
  `
);

const OutlinkIcon = styled.div(
  ({ theme }) => css`
    width: ${theme.space['3.5']};
    height: ${theme.space['3.5']};
    opacity: 0.5;
  `
);

export const OutlinkTypography = styled(Typography)(
  () => css`
    display: inline-block;
  `
);

const PlusPrefix = styled.svg(
  ({ theme }) => css`
    display: block;
    stroke-width: ${theme.space['0.75']};
    height: ${theme.space['5']};
    width: ${theme.space['5']};
  `
);

const NameItemWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    padding: ${theme.space['3']} ${theme.space['4']};
    gap: ${theme.space['2']};
    border-bottom: 1px solid ${theme.colors.border};
    transition: all 0.15s ease-in-out, border 0s;
    background: ${theme.colors.backgroundPrimary};
    cursor: pointer;
    &:hover {
      background: ${theme.colors.backgroundSecondary};
    }
    &:last-of-type {
      border: none;
    }
    ${mq.sm.min(css`
      padding: ${theme.space['3']} ${theme.space['4.5']};
      gap: ${theme.space['4']};
    `)}
  `
);

const NameItemContainer = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 0;
    gap: ${theme.space['2']};
    flex-gap: 16px;
  `
);

const SubDomainContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    font-weight: ${theme.fontWeights.bold};
    line-height: 1.36;
    overflow: hidden;
  `
);

const NameItemContent = styled.div(
  () => css`
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    min-width: 0;
    width: 0;
  `
);

const NoDataFound = styled.div(
  () => css`
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

const Tld = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.textTertiary};
    white-space: nowrap;
  `
);

const SubtitleWrapper = styled.div(
  ({ theme }) => css`
    font-size: ${theme.space['3.5']};
    line-height: 1.43;
    color: ${theme.colors.greyPrimary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
);

const ExpiryText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.greyPrimary};
  `
);

const Footer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${theme.space['8']};
    border-top: 1px solid ${theme.colors.border};
  `
);

const SectionSubtitle = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.textTertiary};
  `
);

const Actions = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row wrap;
    gap: ${theme.space['2']};

    border-top: 1px solid ${theme.colors.border};
    padding: ${theme.space['4']};

    ${mq.sm.min(css`
      padding: ${theme.space['4']} ${theme.space['6']};
    `)}
  `
);

const NFTContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space['56']};
    border-radius: ${theme.radii['2xLarge']};
    overflow: hidden;
  `
);

const ItemsContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${theme.space['4']};

    overflow: hidden;

    ${mq.sm.min(css`
      flex-direction: row;
    `)}
  `
);

const IdsContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: ${theme.space['4']};

    ${mq.sm.min(css`
      gap: ${theme.space['2']};
    `)}
  `
);

const StyledWrapButton = styled(Button)(
  ({ theme }) => css`
    width: 100%;
    ${mq.sm.min(css`
      max-width: ${theme.space['36']};
    `)}
  `
);

const AvatarLogo = styled.div(
  ({ gradient, size }: any) => css`
    width: ${size}px;
    height: ${size}px;
    border-radius: 50%;
    border: 1px solid transparent;
    background: ${gradient};
  `
);

const sortTypeOptions = [
  { label: 'Creation Date', value: 'createdAt' },
  { label: 'Name', value: 'name' },
];

const ProfilePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const name = params?.id;

  const pathParts = location.pathname.split('/');
  const activeTab = pathParts[pathParts.length - 1];
  const [ensAddress, setEnsAddress] = useState('');
  const [gracePeriod, setGracePeriod] = useState<Date>();
  const [expiryDate, setExpiryDate] = useState<any>('');
  const [creationDate, setCreationDate] = useState<any>('');
  // const [record, setRecord] = useState<any>('');
  const [hex, setHex] = useState<any>('');
  const [decimal, setDecimal] = useState<any>('');
  const [resolver, setResolver] = useState<any>('');
  const [subDomainModal, setSubDomainModal] = useState<boolean>(false);
  const [sortTypeChange, setSortTypeChange] = useState('createdAt');
  const [sortDirectionChange, setSortDirectionChange] = useState('asc');
  const [searchChange, setSearchChange] = useState('');
  const [nameList, setNameList] = useState([]);
  const [recordModal, setRecordModal] = useState<boolean>(false);
  const [recordData, setRecordData] = useState<any>([]);
  const [wrapModal, setWrapModal] = useState<boolean>(false);

  const AvatarWrapper = ({ colors, size }: any) => {
    const gradient = generateRandomGradient(colors);
    // @ts-ignore
    return <AvatarLogo gradient={gradient} size={size ?? 35} />;
  };

  const REGISTRATION_DOMAINS_QUERY = gql`
    query ($name: String!) {
      wrappedDomains(where: { name: $name }) {
        domain {
          name
          wrappedOwner {
            id
          }
          createdAt
          expiryDate
        }
      }
    }
  `;

  const SUB_DOMAINS_QUERY = gql`
    query ($name: String!) {
      domains(where: { name: $name }) {
        subdomainCount
        subdomains {
          name
          expiryDate
        }
      }
    }
  `;

  const RECORDS_FETCH_QUERY = gql`
    query ($name: String!) {
      domains(where: { name: $name }) {
        resolver {
          textEvents: events(orderBy: blockNumber, orderDirection: desc) {
            ... on TextChanged {
              key
              value
              blockNumber
            }
          }
        }
      }
    }
  `;

  const { data, refetch } = useQuery(REGISTRATION_DOMAINS_QUERY, {
    variables: { name },
    skip: !name,
  });

  const subDomainData = useQuery(SUB_DOMAINS_QUERY, {
    variables: { name },
    skip: !name,
  });

  const recordFetchingData = useQuery(RECORDS_FETCH_QUERY, {
    variables: { name },
    skip: !name,
  });

  const fetch = async () => {
    if (name) {
      const address = await fetchENSAddress(name);
      setEnsAddress(address);
    }
  };

  useEffect(() => {
    fetch();
    refetch();
    subDomainData?.refetch();
    recordFetchingData?.refetch();
  }, [name, refetch, subDomainData?.refetch, recordFetchingData?.refetch]);

  useEffect(() => {
    if (data?.wrappedDomains?.[0]?.domain) {
      const expiryDate = data?.wrappedDomains?.[0]?.domain?.expiryDate;
      const creationDate = data?.wrappedDomains?.[0]?.domain?.createdAt;
      const expiryPeriod = new Date(expiryDate * 1000);
      const creationPeriod = new Date(creationDate * 1000);
      const gracePeriod = new Date(
        expiryPeriod.getTime() + 90 * 24 * 60 * 60 * 1000
      );
      setCreationDate(creationPeriod);
      setExpiryDate(expiryDate);
      setGracePeriod(gracePeriod);
    }
  }, [data]);

  useEffect(() => {
    if (subDomainData?.data?.domains?.[0]?.subdomainCount > 0) {
      setNameList(subDomainData?.data?.domains?.[0]?.subdomains);
    }
  }, [subDomainData, subDomainData.refetch]);

  useEffect(() => {
    const latestEntries: any = {};
    let recordData =
      recordFetchingData?.data?.domains?.[0]?.resolver?.textEvents;
    // console.log('recordFetchingData', recordFetchingData);
    recordData?.forEach((entry: any) => {
      if (entry.__typename && entry.blockNumber && entry.key) {
        const key = entry.key;
        const blockNumber = entry.blockNumber;
        if (
          !latestEntries[key] ||
          blockNumber > latestEntries[key].blockNumber
        ) {
          latestEntries[key] = entry;
        }
      }
    });
    const filteredData = Object.values(latestEntries);
    // console.log('filteredData', filteredData);
    if (filteredData?.length > 0) {
      setRecordData(filteredData);
    }
  }, [recordFetchingData, subDomainData.refetch, recordModal]);

  async function tokenData() {
    // const {nameWrapperContract}=await getContractInstance();
    const node = ethers.utils.namehash(`${params.id}`);
    const hash = ethers.utils.namehash(node);
    setHex(hash);
    const dec = ethers.BigNumber.from(hash);
    setDecimal(dec.toString());
    const resolver = await getResolver();
    setResolver(resolver);
  }

  const toogleSubDomainModal = () => {
    setSubDomainModal(!subDomainModal);
  };

  const toogleRecordModal = () => {
    setRecordModal(!recordModal);
  };


  useEffect(() => {
    tokenData();
  }, []);

  const SvgCard = ({ name }: any) => {
    return (
      <svg
        viewBox="0 0 270 270"
        display="block"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="url(#paint0_linear)" width="270" height="270"></rect>
        <defs>
          <filter
            id="dropShadow"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
            height="270"
            width="270"
          >
            <feDropShadow
              dx="0"
              dy="1"
              stdDeviation="2"
              floodOpacity="0.225"
              width="200%"
              height="200%"
            ></feDropShadow>
          </filter>
        </defs>
        <path
          d="M38.0397 51.0875C38.5012 52.0841 39.6435 54.0541 39.6435 54.0541L52.8484 32L39.9608 41.0921C39.1928 41.6096 38.5628 42.3102 38.1263 43.1319C37.5393 44.3716 37.2274 45.7259 37.2125 47.1C37.1975 48.4742 37.4799 49.8351 38.0397 51.0875Z"
          fill="white"
          filter="url(#dropShadow)"
        ></path>
        <path
          d="M32.152 59.1672C32.3024 61.2771 32.9122 63.3312 33.9405 65.1919C34.9689 67.0527 36.3921 68.6772 38.1147 69.9567L52.8487 80C52.8487 80 43.6303 67.013 35.8549 54.0902C35.0677 52.7249 34.5385 51.2322 34.2926 49.6835C34.1838 48.9822 34.1838 48.2689 34.2926 47.5676C34.0899 47.9348 33.6964 48.6867 33.6964 48.6867C32.908 50.2586 32.371 51.9394 32.1043 53.6705C31.9508 55.5004 31.9668 57.3401 32.152 59.1672Z"
          fill="white"
          filter="url(#dropShadow)"
        ></path>
        <path
          d="M70.1927 60.9125C69.6928 59.9159 68.4555 57.946 68.4555 57.946L54.1514 80L68.1118 70.9138C68.9436 70.3962 69.6261 69.6956 70.099 68.8739C70.7358 67.6334 71.0741 66.2781 71.0903 64.9029C71.1065 63.5277 70.8001 62.1657 70.1927 60.9125Z"
          fill="white"
          filter="url(#dropShadow)"
        ></path>
        <path
          d="M74.8512 52.8328C74.7008 50.7229 74.0909 48.6688 73.0624 46.8081C72.0339 44.9473 70.6105 43.3228 68.8876 42.0433L54.1514 32C54.1514 32 63.3652 44.987 71.1478 57.9098C71.933 59.2755 72.4603 60.7682 72.7043 62.3165C72.8132 63.0178 72.8132 63.7311 72.7043 64.4324C72.9071 64.0652 73.3007 63.3133 73.3007 63.3133C74.0892 61.7414 74.6262 60.0606 74.893 58.3295C75.0485 56.4998 75.0345 54.66 74.8512 52.8328Z"
          fill="white"
          filter="url(#dropShadow)"
        ></path>
        <text
          x="32.5"
          y="231"
          opacity="1"
          fill="white"
          filter="url(#dropShadow)"
          className="sc-91b0062b-0 eEbIvs"
          style={{ fontSize: '33px' }}
        >
          {name}
        </text>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="190.5"
            y1="302"
            x2="-64"
            y2="-172.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#44BCF0"></stop>
            <stop offset="0.428185" stopColor="#628BF3"></stop>
            <stop offset="1" stopColor="#A099FF"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="0"
            y1="0"
            x2="269.553"
            y2="285.527"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EB9E9E"></stop>
            <stop offset="1" stopColor="#992222"></stop>
          </linearGradient>
        </defs>
      </svg>
    );
  };

  return (
    <div className="profile-section">
      <Heading align="left">{name}</Heading>
      <div className="profile-option">
        <button
          className={`profile-tab ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => navigate(`/${name}/profile`)}
        >
          Profile
        </button>
        <button
          className={`records-tab ${activeTab === 'records' ? 'active' : ''}`}
          onClick={() => navigate(`/${name}/records`)}
        >
          Records
        </button>
        <button
          className={`ownership-tab ${
            activeTab === 'ownership' ? 'active' : ''
          }`}
          onClick={() => navigate(`/${name}/ownership`)}
        >
          Ownership
        </button>

        <button
          className={`profile-tab ${
            activeTab === 'subdomains' ? 'active' : ''
          }`}
          onClick={() => navigate(`/${name}/subdomains`)}
        >
          Sub domains
        </button>

        <button
          className={`more-tab ${activeTab === 'more' ? 'active' : ''}`}
          onClick={() => navigate(`/${name}/more`)}
        >
          More
        </button>
      </div>
      {activeTab === 'profile' && (
        <>
          <Container data-testid="profile-snippet">
            <FirstItems>
              <AvatarWrapper colors={colors} size={100} />
            </FirstItems>
            <TextStack>
              <DetailStack>
                <Name
                  fontVariant="headingTwo"
                  data-testid="profile-snippet-name"
                >
                  {name}
                </Name>
              </DetailStack>
            </TextStack>
          </Container>
          <ProfileInfoBox>
            <RecordsStack>
              <Typography weight="bold">Addresses</Typography>
              <RecordItem
                inline
                value={data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id}
              >{`${data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id.slice(
                0,
                5
              )}...${data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id.slice(
                -5
              )}`}</RecordItem>
            </RecordsStack>
            {/* <RecordsStack>
              <Typography weight="bold">Other Records</Typography>
              <RecordItem inline value="https://ens.domains">
                ipfs://bafybeia...
              </RecordItem>
            </RecordsStack> */}
            <RecordsStack>
              <Typography weight="bold">
                {containsTwoDots(params?.id) ? 'Manager' : 'Owner'}
              </Typography>
              <div
                style={{ flexDirection: 'row', display: 'flex', gap: '10px' }}
              >
                <RecordItem
                  inline
                  value={data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id}
                  keyLabel={containsTwoDots(params?.id) ? 'manager' : 'owner'}
                >
                  {`${data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id?.slice(
                    0,
                    5
                  )}...${data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id?.slice(
                    -5
                  )}`}
                </RecordItem>
                <RecordItem
                  inline
                  value={
                    containsTwoDots(params?.id)
                      ? 'no expiry'
                      : `${new Date(expiryDate * 1000)?.toUTCString()}`
                  }
                  keyLabel="expiry"
                >
                  {containsTwoDots(params?.id)
                    ? 'no expiry'
                    : `${new Date(expiryDate * 1000)?.toUTCString()}`}
                </RecordItem>
                {params?.id && (
                  <RecordItem
                    inline
                    value={
                      (params.id?.match(/\./g)?.length ?? 0) >= 2
                        ? `${params.id.split('.').slice(1).join('.')}`
                        : 'edx'
                    }
                    keyLabel="parent"
                  >
                    {(params.id?.match(/\./g)?.length ?? 0) >= 2
                      ? `${params.id.split('.').slice(1).join('.')}`
                      : 'edx'}
                  </RecordItem>
                )}
              </div>
            </RecordsStack>
          </ProfileInfoBox>
        </>
      )}
      {activeTab === 'records' && (
        <>
          <ProfileInfoBox>
            <RecordsStack>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}
              >
                <SectionSubtitle fontVariant="bodyBold">Text</SectionSubtitle>
                {recordData?.length > 0 ? (
                  <SectionSubtitle>
                    {recordData?.length} Records
                  </SectionSubtitle>
                ) : (
                  <SectionSubtitle>No Records</SectionSubtitle>
                )}
              </div>
              {recordData.map((element: any, index: number) => (
                <RecordItem
                  keyLabel={element?.key}
                  value={element?.value}
                  key={index}
                >
                  {element?.value}
                </RecordItem>
              ))}
            </RecordsStack>
            <RecordsStack>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}
              >
                <SectionSubtitle fontVariant="bodyBold">
                  Addresses
                </SectionSubtitle>
                {/* <SectionSubtitle>1 Records</SectionSubtitle> */}
              </div>
              <RecordItem
                style={{ opacity: '90%' }}
                keyLabel="edx"
                value={data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id}
              >
                {data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id}
              </RecordItem>
            </RecordsStack>

            <RecordsStack>
              <SectionSubtitle>No Hash</SectionSubtitle>
            </RecordsStack>
            <RecordsStack>
              <SectionSubtitle>No ABI</SectionSubtitle>
            </RecordsStack>
            <Actions>
              <div>
                <Button size="small" onClick={() => setRecordModal(true)}>
                  Edit Records
                </Button>
              </div>
            </Actions>
          </ProfileInfoBox>
          <CreateRecordModal
            open={recordModal}
            setOpen={toogleRecordModal}
            refetch={subDomainData.refetch}
          />
        </>
      )}
      {activeTab === 'ownership' && (
        <>
          <ProfileInfoBox>
            <Card>
              <Heading>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  Roles
                  <Tag size="small">2 addresses</Tag>
                </div>
              </Heading>
              <Card.Divider />
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <div style={{ width: '35px' }}>
                    <Avatar label="ownership-avatar" />
                  </div>
                  <Typography>{`${data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id?.slice(
                    0,
                    5
                  )}...${data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id?.slice(
                    -5
                  )}`}</Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                  }}
                >
                  <Tag size="small">
                    {containsTwoDots(params?.id) ? 'Parent owner' : 'Owner'}
                  </Tag>
                  {containsTwoDots(params?.id) && (
                    <Tag size="small">Manager</Tag>
                  )}
                </div>
              </Typography>
              {!containsTwoDots(params?.id) && (
                <>
                  <Card.Divider />
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <div style={{ width: '35px' }}>
                        <Avatar label="ownership-avatar" />
                      </div>
                      <Typography>{`${data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id?.slice(
                        0,
                        5
                      )}...${data?.wrappedDomains?.[0]?.domain?.wrappedOwner?.id?.slice(
                        -5
                      )}`}</Typography>
                    </div>
                    <Tag size="small">ETH Record</Tag>
                  </Typography>
                </>
              )}
            </Card>
          </ProfileInfoBox>
          <ProfileInfoBox>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '1rem 2rem',
                justifyContent: 'space-between',
              }}
            >
              {containsTwoDots(params?.id) ? (
                <>
                  <PanelsContainer>
                    <Typography fontVariant="bodyBold" color="text">
                      Name expires
                    </Typography>
                    <Body>
                      <Typography fontVariant="small" color="grey">
                        No Expiry
                      </Typography>
                    </Body>
                  </PanelsContainer>
                  {creationDate && (
                    <PanelsContainer>
                      <Typography fontVariant="bodyBold" color="text">
                        Registered
                      </Typography>
                      <Body>
                        <Typography fontVariant="small" color="grey">
                          {new Date(creationDate).toUTCString()}
                        </Typography>
                      </Body>
                    </PanelsContainer>
                  )}
                </>
              ) : (
                <>
                  <PanelsContainer>
                    <Typography fontVariant="bodyBold" color="text">
                      Name expires
                    </Typography>
                    <Body>
                      <Typography fontVariant="small" color="grey">
                        {new Date(expiryDate * 1000).toUTCString()}
                      </Typography>
                    </Body>
                  </PanelsContainer>
                  {gracePeriod && (
                    <PanelsContainer>
                      <Typography fontVariant="bodyBold" color="text">
                        Grace period ends
                      </Typography>
                      <Body>
                        <Typography fontVariant="small" color="grey">
                          {gracePeriod?.toUTCString()}
                        </Typography>
                      </Body>
                    </PanelsContainer>
                  )}
                  {creationDate && (
                    <PanelsContainer>
                      <Typography fontVariant="bodyBold" color="text">
                        Registered
                      </Typography>
                      <Body>
                        <Typography fontVariant="small" color="grey">
                          {new Date(creationDate).toUTCString()}
                        </Typography>
                      </Body>
                    </PanelsContainer>
                  )}
                </>
              )}
            </div>
          </ProfileInfoBox>
        </>
      )}
      {activeTab === 'more' && (
        <>
          <ProfileInfoBox style={{ opacity: '80%' }}>
            <Card>
              <Heading>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  Token
                </div>
              </Heading>
              <Card.Divider />
              <ItemsContainer>
                <IdsContainer>
                  <RecordItem
                    keyLabel="hex"
                    value="0x0b40f4d74df87e9863fbb24bdc51fae3d3dc7b73d71490251837a1824b95099f"
                  >
                    {hex}
                  </RecordItem>
                  <RecordItem
                    keyLabel="decimal"
                    value="5090209377270168375949025966987675703285484513529565820401966869130612378015"
                  >
                    {decimal}
                  </RecordItem>
                </IdsContainer>
                {!containsTwoDots(params?.id) && (
                  <NFTContainer>
                    <SvgCard name={params?.id} />
                  </NFTContainer>
                )}
              </ItemsContainer>
              <Card.Divider />
              <ItemsContainer>
                <RecordItem keyLabel="wrapper" value="unwrapped">
                  wrapped
                </RecordItem>
                {/* <StyledWrapButton
                  onClick={() => setWrapModal(true)}
                  size="small"
                  colorStyle="accentPrimary"
                >
                  Unwrap
                </StyledWrapButton> */}
              </ItemsContainer>
            </Card>
          </ProfileInfoBox>
          <ProfileInfoBox style={{ opacity: '90%' }}>
            <Card>
              <Heading>Resolver</Heading>
              <RecordItem value="0x4976fb03C32e5B8cfe2b6cCB31c09Ba78EBaBa41">
                {resolver}
              </RecordItem>
            </Card>
          </ProfileInfoBox>
          {wrapModal && (
            <WrapModal open={wrapModal} onDismiss={() => setWrapModal(false)} />
          )}
        </>
      )}

      {activeTab === 'subdomains' && (
        <>
          <TabWrapperWithButtons>
            <AddSubnamesCard>
              <Typography
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  height: 'auto',
                }}
              >
                Subnames let you create additional names from your existing
                name.
                <StyledAnchor
                  rel="noreferrer noopener"
                  target="_blank"
                  role="link"
                >
                  <OutlinkIcon as={OutlinkSVG} />
                  <OutlinkTypography fontVariant="smallBold" color="blue">
                    Learn about subnames
                  </OutlinkTypography>
                </StyledAnchor>
              </Typography>
              <Button
                data-testid="add-subname-action"
                onClick={toogleSubDomainModal}
                prefix={<PlusPrefix as={PlusSVG} />}
              >
                New Subname
              </Button>
            </AddSubnamesCard>
          </TabWrapperWithButtons>
          {subDomainModal && (
            <CreateSubname
              open={subDomainModal}
              setOpen={toogleSubDomainModal}
              refetch={subDomainData?.refetch}
            />
          )}
          <StyledCard>
            <TabWrapperWithButtons>
              <TableHeader>
                <TableHeaderLeading>
                  <TableHeaderLeadingLeft>
                    <TableHeaderLeftControlsContainer>
                      <Select
                        value={sortTypeChange}
                        size="small"
                        label="Sort by"
                        hideLabel
                        onChange={(e) => setSortTypeChange(e.target.value)}
                        options={sortTypeOptions}
                        id="sort-by"
                      />
                      <DirectionButton
                        onClick={() => setSortDirectionChange('asc')}
                      >
                        <ListDownSVG />
                      </DirectionButton>
                      <DirectionButton
                        onClick={() => setSortDirectionChange('desc')}
                      >
                        <ListUpSVG />
                      </DirectionButton>
                    </TableHeaderLeftControlsContainer>
                  </TableHeaderLeadingLeft>
                </TableHeaderLeading>
                <TableHeaderTrailing>
                  <Input
                    data-testid="name-table-header-search"
                    size="small"
                    label="search"
                    value={searchChange}
                    onChange={(e) => setSearchChange(e.target.value)}
                    hideLabel
                    icon={<MagnifyingGlassSimpleSVG />}
                    placeholder="Search"
                  />
                </TableHeaderTrailing>
              </TableHeader>
              <div data-testid="names-list">
                {nameList?.length > 0 ? (
                  nameList?.map((name: any) => (
                    <NameItemWrapper
                      key={name?.name}
                      onClick={() => navigate(`/${name?.name}/profile`)}
                    >
                      <NameItemContainer>
                        <AvatarWrapper colors={colors} size={35} />
                        <NameItemContent>
                          <SubDomainContainer>
                            <Name>{name?.name?.split('.edx')[0]}.</Name>
                            <Tld>edx</Tld>
                          </SubDomainContainer>
                        </NameItemContent>
                      </NameItemContainer>
                      <Tag>Manager</Tag>
                    </NameItemWrapper>
                  ))
                ) : (
                  <NoDataFound>No names found for this address</NoDataFound>
                )}
              </div>
              <Footer />
            </TabWrapperWithButtons>
          </StyledCard>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
