import { combineReducers, configureStore } from '@reduxjs/toolkit';
import walletAddressSlice from './WalletAddressSlice';
import connectedSlice from './ConnectedSlice';
import registerPageSlice from './RegisterPageSlice';

const rootReducer = combineReducers({
  walletAddress: walletAddressSlice,
  connected: connectedSlice,
  registerPage: registerPageSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
