import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ONE_YEAR, yearsToSeconds } from '../../utils/constants/Constants';
import { Button, Helper, Typography, mq } from '@ensdomains/thorin';
import { PlusMinusControl } from '../PlusMinusControl/PlusMinusControl';
import FullInvoice from '../FullInvoice/FullInvoice';
import { RootState } from '../../store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsConnected } from '../../store/ConnectedSlice';
import { setWalletAddress } from '../../store/WalletAddressSlice';
import { setRegisterPage } from '../../store/RegisterPageSlice';
import { useNavigate, useParams } from 'react-router-dom';

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space['2']};
    align-items: center;
    width: ${theme.space.full};
  `
);

const DateCard = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space['2']};
    align-items: center;
    width: ${theme.space.full};
  `
);

const Bar = styled.div<{ $highlightPercent: number }>(
  ({ theme, $highlightPercent }) => css`
    --bar-width: calc(${$highlightPercent}% - ${theme.space['1']});
    background: linear-gradient(
      90deg,
      ${theme.colors.red} var(--bar-width),
      ${theme.colors.blue} var(--bar-width)
    );
    width: 100%;
    height: ${theme.space['4']};
    border-radius: ${theme.radii.medium};
    margin-bottom: ${theme.space['11']};
  `
);

const Marker = styled.div<{ $percent: number }>(
  ({ theme, $percent }) => css`
    position: absolute;
    transform-style: preserve-3d;
    bottom: 0;
    left: ${$percent}%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.space['1']};

    width: ${theme.space['16']};
    height: ${theme.space['10']};
    padding: 0 ${theme.space['2']};

    border-radius: ${theme.radii.medium};
    background-color: ${theme.colors.background};
    &:last-of-type {
      background-color: ${theme.colors.blue};
      color: ${theme.colors.background};
    }

    font-size: ${theme.fontSizes.extraSmall};
    line-height: ${theme.lineHeights.extraSmall};

    b {
      display: block;
      white-space: nowrap;
    }

    :not(b) {
      white-space: nowrap;
    }

    ${mq.xs.min(css`
      width: ${theme.space['18']};
      font-size: ${theme.fontSizes.small};
    `)}

    &::before {
      content: '';

      position: absolute;
      transform: translateZ(-1px);
      bottom: ${theme.space['9']};

      height: ${theme.space['7']};
      width: ${theme.space['1']};

      outline: ${theme.space['0.5']} solid ${theme.colors.blueSurface};

      border-radius: ${theme.radii.medium};
      background-color: inherit;
    }
  `
);

const InnerContainer = styled.div(
  ({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: ${theme.space['4']};
  `
);

const InfoItems = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: ${theme.space['4']};

    ${mq.sm.min(css`
      flex-direction: row;
      align-items: stretch;
    `)}
  `
);

const InfoItem = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${theme.space['4']};

    padding: ${theme.space['4']};
    border: 1px solid ${theme.colors.border};
    border-radius: ${theme.radii.large};
    text-align: center;

    & > div:first-of-type {
      width: ${theme.space['10']};
      height: ${theme.space['10']};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${theme.fontSizes.extraLarge};
      font-weight: ${theme.fontWeights.bold};
      color: ${theme.colors.backgroundPrimary};
      background: ${theme.colors.accentPrimary};
      border-radius: ${theme.radii.full};
    }

    & > div:last-of-type {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `
);

const ButtonContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space.full};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${theme.space['2']};
  `
);

const MobileFullWidth = styled.div(
  ({ theme }) => css`
    & > div,
    & {
      width: ${theme.space.full};
      max-width: ${theme.space.full};
      ${mq.sm.min(css`
        min-width: ${theme.space['40']};
        width: fit-content;
        max-width: max-content;
      `)}
    }
  `
);

export const DateSelection = ({
  seconds,
  setSeconds,
  name,
  minSeconds,
  mode = 'register',
  expiry,
}: {
  seconds: number;
  setSeconds: (seconds: number) => void;
  name?: string;
  minSeconds: number;
  mode?: 'register' | 'extend';
  expiry?: number;
}) => {
  const [dateInYears, setDateInYears] = useState<number>(1);
  const { isConnected } = useSelector((state: RootState) => state.connected);
  const { registerPage } = useSelector(
    (state: RootState) => state.registerPage
  );

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const connectWallet = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      dispatch(setWalletAddress(accounts[0]));
      if (accounts.length > 0) {
        dispatch(setIsConnected(true));
        localStorage.setItem('connected', 'true');
        localStorage.setItem('walletAddress', accounts[0]);
      }
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };

  const ethItems = [
    'Complete a transaction to begin the timer',
    'Wait 10 seconds for the timer to complete',
    'Complete a second transaction to secure your name',
  ];

  useEffect(() => {
    if (minSeconds > seconds) setSeconds(minSeconds);
  }, [minSeconds, seconds, setSeconds]);

  useEffect(() => {
    if (dateInYears < 1) {
      setSeconds(ONE_YEAR);
    }
  }, [dateInYears, setSeconds]);

  return (
    <Container>
      {!registerPage ? (
        <>
          <DateCard>
            <PlusMinusControl
              highlighted
              minValue={1}
              value={dateInYears}
              setValue={setDateInYears}
              onChange={(e: { target: { value: string } }) => {
                const newYears = parseInt(e.target.value);
                if (!Number.isNaN(newYears))
                  setSeconds(yearsToSeconds(newYears));
              }}
            />
          </DateCard>
          <Typography color="greyPrimary" fontVariant="smallBold">
            {`${dateInYears} ${mode === 'register' ? 'year' : 'extension'}${
              dateInYears > 1 ? 's' : ''
            } registration.`}{' '}
          </Typography>
        </>
      ) : (
        <InfoItems>
          {ethItems.map((item, inx) => (
            <InfoItem key={item}>
              <Typography>{inx + 1}</Typography>
              <Typography>{item}</Typography>
            </InfoItem>
          ))}
        </InfoItems>
      )}
      <FullInvoice dateInYears={dateInYears} />
      {!registerPage ? (
        <>
          <Helper type="info">
            <InnerContainer>
              <div>
                Extending for multiple years will save money on network costs by
                avoiding yearly transactions.
              </div>
              <Bar $highlightPercent={70} />
              <Marker data-testid="year-marker-0" $percent={10}>
                <b>1 year</b>
                9% gas
              </Marker>
              <Marker data-testid="year-marker-1" $percent={40}>
                <b>3 years</b>
                5% gas
              </Marker>
              <Marker data-testid="year-marker-2" $percent={90}>
                <b>5 years</b>
                2% gas
              </Marker>
            </InnerContainer>
          </Helper>
          <Button
            width="45"
            onClick={
              isConnected
                ? () => dispatch(setRegisterPage(true))
                : connectWallet
            }
          >
            {isConnected ? 'Next' : 'Connect'}
          </Button>
        </>
      ) : (
        <ButtonContainer>
          <MobileFullWidth>
            <Button colorStyle="accentSecondary" onClick={() => dispatch(setRegisterPage(false))}>Back</Button>
          </MobileFullWidth>
          <MobileFullWidth>
            <Button data-testid="next-button" onClick={() => navigate(`/${params?.id}/timer`)}>Begin</Button>
          </MobileFullWidth>
        </ButtonContainer>
      )}
    </Container>
  );
};