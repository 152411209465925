import { useEffect, useState } from 'react';
import './App.scss';
import Header from './components/Header/Header';
import TestnetWarning from './components/TestnetWarning/TestnetWarning';
import ConnectedRouter from './routers/ConnectedRouter';
import { getNetworkID } from './common';
import UnsupportedNetworkPage from './pages/UnsupportedNetworkPage';

const App = () => {
  const [chainId, setChainId] = useState(0);

  const fetchNetworkId = async () => {
    const networkId = await getNetworkID();
      setChainId(networkId);
   
  };

  useEffect(() => {
    fetchNetworkId();
  });

  return (
    <>
      {chainId === 1995 ? (
        <>
          <TestnetWarning />
          <div className="container min-safe">
            <Header />
            <ConnectedRouter />
          </div>
        </>
      ) : (
        <div className="container min-safe">
          <Header />
          <ConnectedRouter />
          {/* <UnsupportedNetworkPage /> */}
        </div>
      )}
    </>
  );
};

export default App;
