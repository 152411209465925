import styled, { css } from 'styled-components';
import { getNetworkID } from '../../common';
import { useEffect, useState } from 'react';

const Container = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.yellowSurface};
    color: ${theme.colors.textPrimary};
    font-size: ${theme.fontSizes.small};
    padding: ${theme.space['2']} ${theme.space['4']};
    text-align: center;
    font-weight: ${theme.fontWeights.bold};
  `
);

const TestnetWarning = () => {
  const [chainId, setChainId] = useState(0);

  const fetchNetworkId = async () => {
    const networkId = await getNetworkID();
    setChainId(networkId);
  };

  useEffect(() => {
    const handleChainChanged = () => {
      fetchNetworkId();
    };
    handleChainChanged();

    if (window.ethereum) {
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.off('chainChanged', handleChainChanged);
      }
    };
  });

  if (chainId === 1995)
    return <Container>You are viewing the EDNS app on EdeXa testnet</Container>;
  // else if (chainId === 17000)
  //   return (
  //     <Container>You are viewing the EDNS app on Holesky testnet</Container>
    // );
  else return null;
};

export default TestnetWarning;
