import { Outlet, RouteObject } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import RegisterPage from '../pages/RegisterPage';
import TimerPage from '../pages/TimerPage';
import UnsupportedNetworkPage from '../pages/UnsupportedNetworkPage';
import ProfilePage from '../pages/ProfilePage';
import MyNamePage from '../pages/MyNamePage';
import SuccessPage from '../pages/SuccessPage';

export const connectedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: '/:id/register',
        element: <RegisterPage />,
      },
      {
        path: '/:id/timer',
        element: <TimerPage />,
      },
      {
        path: '/unsupportedNetwork',
        element: <UnsupportedNetworkPage />,
      },
      {
        path: '/:id/profile',
        element: <ProfilePage />,
      },
      {
        path: '/:id/records',
        element: <ProfilePage />,
      },
      {
        path: '/:id/ownership',
        element: <ProfilePage />,
      },
      {
        path: '/:id/more',
        element: <ProfilePage />,
      },
      {
        path: '/:id/subdomains',
        element: <ProfilePage />,
      },
      {
        path: '/my/names',
        element: <MyNamePage />,
      },
      {
        path: '/:id/successPage',
        element: <SuccessPage />,
      },
      {
        path: '*',
        element: <LandingPage />,
      },
    ],
  },
];
