import { Button, Card, Dialog, Typography, mq } from '@ensdomains/thorin';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import RecordTab from './RecordTab';
import { getContractInstance } from '../../common';
import { ethers } from 'ethers';
import { ReactComponent as WalletSVG } from '../../assets/Wallet.svg';

const InnerDialog = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: ${theme.space.full};
    gap: ${theme.space['4']};
    max-height: 60vh;
    max-width: 100vw;
    ${mq.sm.min(css`
      width: calc(80vw - 2 * ${theme.space['6']});
      max-width: ${theme.space['128']};
    `)}
  `
);

const Container = styled.form(({ theme }) => [
  css`
    width: 100%;
    max-height: min(80vh, 600px);
    background: ${theme.colors.backgroundPrimary};
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  mq.sm.min(css`
    width: calc(80vw - 2 * ${theme.space['6']});
    max-width: ${theme.space['128']};
  `),
]);

const NameContainer = styled.div(({ theme }) => [
  css`
    display: block;
    width: 100%;
    padding-left: ${theme.space['2']};
    padding-right: ${theme.space['4']};
    letter-spacing: ${theme.letterSpacings['-0.01']};
    line-height: 45px;
    vertical-align: middle;
    text-align: center;
    font-feature-settings: 'ss01' on, 'ss03' on, 'ss04' on;
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.space['6']};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  mq.sm.min(css`
    text-align: left;
  `),
]);

const ContentContainer = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    margin-top: ${theme.space['4.5']};
    flex-direction: column;
    overflow: hidden;
    gap: ${theme.space['4']};
  `
);

const FooterContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.space['3']};
    width: 100%;
    max-width: ${theme.space['96']};
    margin: 0 auto;
  `
);

const StyledCard = styled(Card)(
  ({ theme }) => css`
    max-width: 780px;
    margin: 0 auto;
    flex-direction: column;
    gap: ${theme.space['4']};
    padding: ${theme.space['4']};
    align-items: center;

    ${mq.sm.min(css`
      padding: ${theme.space['6']} ${theme.space['18']};
      gap: ${theme.space['6']};
    `)}
  `
);

const DialogTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.headingThree};
    font-weight: bold;
    text-align: center;
  `
);

const WalletIcon = styled.svg(
  ({ theme }) => css`
    width: ${theme.space['12']};
  `
);

const MessageTypography = styled(Typography)(
  () => css`
    text-align: center;
  `
);

const TableContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    width: ${theme.space.full};
    gap: ${theme.space['2']};
  `
);

const ValueTypography = styled(Typography)(
  ({ theme }) => css`
    overflow-wrap: anywhere;
    text-align: right;
    margin-left: ${theme.space['2']};
  `
);

const DisplayItemContainer = styled.div<{
  $shrink?: boolean;
  $fade?: boolean;
}>(
  ({ theme, $shrink, $fade }) => css`
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    align-items: center;
    border-radius: ${theme.radii.extraLarge};
    border: ${theme.borderWidths.px} ${theme.borderStyles.solid}
      ${theme.colors.border};
    min-height: ${theme.space['14']};
    padding: ${theme.space['2']} ${theme.space['5']};
    width: ${theme.space.full};

    ${$shrink &&
    css`
      min-height: ${theme.space['12']};
      div {
        margin-top: 0;
        align-self: center;
      }
    `}
    ${$fade &&
    css`
      opacity: 0.5;
      background-color: ${theme.colors.backgroundSecondary};
    `}
  `
);

const DisplayItemLabel = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    justify-self: flex-start;
  `
);

const DisplayItemValue = (props: any) => {
  const { value } = props;
  return <ValueTypography fontVariant="bodyBold">{value}</ValueTypography>;
};

const CreateRecordModal = ({ open, setOpen, refetch }: any) => {
  const [record, setRecord] = useState({
    key: '',
    value: '',
  });
  const params = useParams();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<{
    key: string | null;
    value: string | null;
  }>({
    key: null,
    value: null,
  });

  const handleRecordRegistration = async () => {
    try {
      let time = 0;
      setLoading(true);
      const key = record?.key;
      const value = record?.value;
      const { resolverContract } = await getContractInstance();
      const node = ethers.utils.namehash(`${params.id}`);
      const tx = await resolverContract.setText(node, key, value,{gasLimit: 100000,gasPrice: ethers.utils.parseUnits('30', 'gwei')});
      setProgress(0);
      while (time < 97) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        time += 10;
        setProgress(time);
      }
      await tx.wait();
  
      setModalOpen(false);
      setOpen(false);
      refetch();
    } catch (e) {
      console.log(e);
      refetch();
    }
  };

  const displayInfo = [
    {
      label: 'Name',
      value: params?.id,
      type: 'name',
    },
    {
      label: 'Action',
      value: 'Add Record',
    },
    {
      label: 'Update',
      value: `${record.key}: ${record.value}`,
    },
  ];

  return (
    <>
      <Dialog
        variant="blank"
        open={open}
        onDismiss={() => {
          setOpen();
        }}
      >
        <InnerDialog>
          <Container data-testid="advanced-editor">
            <NameContainer>{`${params?.id}'s records`}</NameContainer>
            <ContentContainer>
              <RecordTab
                record={record}
                setRecord={setRecord}
                error={error}
                setError={setError}
              />
              <FooterContainer>
                <Button
                  colorStyle="accentSecondary"
                  onClick={() => {
                    setOpen();
                    setError({
                      key: null,
                      value: null,
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (record.key === '' || record.value === '') {
                      setError({
                        key: 'Label cannot be empty',
                        value: 'Label cannot be empty',
                      });
                      return;
                    }
                    setModalOpen(true);
                  }}
                >
                  Save
                </Button>
              </FooterContainer>
            </ContentContainer>
          </Container>
        </InnerDialog>
      </Dialog>
      {modalOpen && (
        <StyledCard>
          <Dialog
            variant="blank"
            open={modalOpen}
            onDismiss={() => {
              setRecord({ key: '', value: '' });
              setModalOpen(false);
            }}
          >
            <Dialog.CloseButton
              onClick={() => {
                setRecord({ key: '', value: '' });
                setModalOpen(false);
              }}
            />
            <InnerDialog>
              <DialogTitle>Confirm Details</DialogTitle>
              <WalletIcon as={WalletSVG} />
              <MessageTypography>
                Double check these details before confirming in your wallet.
              </MessageTypography>
              <TableContainer>
                {displayInfo?.map((props) => {
                  const { label, value, type } = props;
                  return (
                    <DisplayItemContainer
                      data-testid={`display-item-${label}-normal`}
                      key={`${label}-${value}`}
                    >
                      <DisplayItemLabel>{label}</DisplayItemLabel>
                      <DisplayItemValue {...{ value, type }} />
                    </DisplayItemContainer>
                  );
                })}
              </TableContainer>
              <Dialog.Footer
                trailing={
                  <Button onClick={handleRecordRegistration} disabled={loading}>
                    {loading ? 'Waiting for transaction' : 'Open Wallet'}
                  </Button>
                }
              />
            </InnerDialog>
          </Dialog>
        </StyledCard>
      )}
    </>
  );
};

export default CreateRecordModal;
