import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  registerPage: false,
};

const registerPageSlice = createSlice({
  name: 'registerPage',
  initialState,
  reducers: {
    setRegisterPage: (state, action) => {
      state.registerPage = action.payload;
    },
  },
});

export const { setRegisterPage } = registerPageSlice.actions;

export default registerPageSlice.reducer;
