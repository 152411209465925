import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isConnected: localStorage.getItem('connected') ?? false
};

const connectedSlice = createSlice({
	name: "isConnected",
	initialState,
	reducers: {
		setIsConnected: (state, action) => {
			state.isConnected = action.payload;
		}
	}
});

export const { setIsConnected } = connectedSlice.actions;

export default connectedSlice.reducer;
