import {
  AeroplaneSVG,
  Button,
  Card,
  CountdownCircle,
  Dialog,
  Heading,
  Spinner,
  Typography,
  mq,
} from '@ensdomains/thorin';
import { ReactComponent as WalletSVG } from '../assets/Wallet.svg';
import { ReactNode, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  getWalletAddress,
  getResolver,
  getContractInstance,
  colors,
  generateRandomGradient,
} from './../common';
import { ethers } from 'ethers';

const BarContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space.full};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.space['2']};
  `
);

const Bar = styled.div(
  ({ theme }) => css`
    width: ${theme.space.full};
    height: ${theme.space['9']};
    border-radius: ${theme.radii.full};
    background-color: ${theme.colors.blueSurface};
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    --bar-color: ${theme.colors.blue};
  `
);

const StyledCard = styled(Card)(
  ({ theme }) => css`
    max-width: 780px;
    margin: 0 auto;
    flex-direction: column;
    gap: ${theme.space['4']};
    padding: ${theme.space['4']};
    align-items: center;

    ${mq.sm.min(css`
      padding: ${theme.space['6']} ${theme.space['18']};
      gap: ${theme.space['6']};
    `)}
  `
);

const InnerBar = styled.div(
  ({ theme }) => css`
    padding: ${theme.space['2']} ${theme.space['4']};
    height: ${theme.space['9']};

    border-radius: ${theme.radii.full};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    transition: width 1s linear;
    &.progress-complete {
      width: 100% !important;
      padding-right: ${theme.space['2']};
      transition: width 0.5s ease-in-out;
    }

    background-color: var(--bar-color);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    position: relative;

    & > svg {
      position: absolute;
      right: ${theme.space['2']};
      top: 50%;
      transform: translateY(-50%);
    }
  `
);

const InnerDialog = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: ${theme.space.full};
    gap: ${theme.space['4']};
    max-height: 60vh;
    max-width: 100vw;
    ${mq.sm.min(css`
      width: calc(80vw - 2 * ${theme.space['6']});
      max-width: ${theme.space['128']};
    `)}
  `
);

const DialogTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.headingThree};
    font-weight: bold;
    text-align: center;
  `
);

const StyledCountdown = styled(CountdownCircle)(
  ({ theme, disabled }) => css`
    width: ${theme.space['52']};
    height: ${theme.space['52']};
    & > div {
      font-size: ${theme.fontSizes.headingOne};
      font-weight: ${theme.fontWeights.bold};
      width: ${theme.space['52']};
      height: ${theme.space['52']};
      color: ${theme.colors.accent};
      ${disabled &&
      css`
        color: ${theme.colors.grey};
      `}
    }
    svg {
      stroke-width: ${theme.space['0.5']};
      ${disabled &&
      css`
        stroke: ${theme.colors.grey};
      `}
    }
  `
);

const ButtonContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space.full};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${theme.space['2']};
  `
);

const MobileFullWidth = styled.div(
  ({ theme }) => css`
    & > div,
    & {
      width: ${theme.space.full};
      max-width: ${theme.space.full};
      ${mq.sm.min(css`
        min-width: ${theme.space['40']};
        width: fit-content;
        max-width: max-content;
      `)}
    }
  `
);

const MessageTypography = styled(Typography)(
  () => css`
    text-align: center;
  `
);

const WalletIcon = styled.svg(
  ({ theme }) => css`
    width: ${theme.space['12']};
  `
);

const TableContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    width: ${theme.space.full};
    gap: ${theme.space['2']};
  `
);

const DisplayItemContainer = styled.div<{
  $shrink?: boolean;
  $fade?: boolean;
}>(
  ({ theme, $shrink, $fade }) => css`
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    align-items: center;
    border-radius: ${theme.radii.extraLarge};
    border: ${theme.borderWidths.px} ${theme.borderStyles.solid}
      ${theme.colors.border};
    min-height: ${theme.space['14']};
    padding: ${theme.space['2']} ${theme.space['5']};
    width: ${theme.space.full};

    ${$shrink &&
    css`
      min-height: ${theme.space['12']};
      div {
        margin-top: 0;
        align-self: center;
      }
    `}
    ${$fade &&
    css`
      opacity: 0.5;
      background-color: ${theme.colors.backgroundSecondary};
    `}
  `
);

const AeroplaneIcon = styled.svg(
  ({ theme }) => css`
    width: ${theme.space['4']};
    height: ${theme.space['4']};
    color: ${theme.colors.background};
  `
);

const DisplayItemLabel = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    justify-self: flex-start;
  `
);

const ValueWithAvatarContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: ${theme.space['4']};
  `
);

const ValueTypography = styled(Typography)(
  ({ theme }) => css`
    overflow-wrap: anywhere;
    text-align: right;
    margin-left: ${theme.space['2']};
  `
);

const AvatarLogo = styled.div(
  ({ gradient }: any) => css`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
    background: ${gradient};
  `
);

const TimerPage = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState<boolean>(false); 
  const [progress, setProgress] = useState<number>(0);

  const params = useParams();
  const navigate = useNavigate();

  const yearsCount = Number(sessionStorage.getItem('yearsCount'));

  let BackButton: ReactNode = (
    <MobileFullWidth>
      <Button colorStyle="accentSecondary">Back</Button>
    </MobileFullWidth>
  );

  let ActionButton: ReactNode = (
    <MobileFullWidth>
      <Button
        data-testid="start-timer-button"
        disabled={registerModal}
        onClick={() => setOpen(!open)}
      >
        {registerModal ? 'Register' : 'Start timer'}
      </Button>
    </MobileFullWidth>
  );

  const displayInfo = [
    {
      label: 'Name',
      value: params?.id,
      type: 'name',
    },
    {
      label: 'Action',
      value: 'Start timer',
    },
    {
      label: 'Info',
      value: 'Start timer to register name',
    },
  ];

  const displayRegisterInfo = [
    {
      label: 'Name',
      value: params?.id,
      type: 'name',
    },
    {
      label: 'Action',
      value: 'Register name',
    },
  ];

  const AvatarWrapper = ({ colors }: any) => {
    const gradient = generateRandomGradient(colors);
    // @ts-ignore
    return <AvatarLogo gradient={gradient} />;
  };

  const NameValue = ({ value }: { value: string }) => {
    return (
      <ValueWithAvatarContainer>
        <ValueTypography fontVariant="bodyBold">{value}</ValueTypography>
        <AvatarWrapper colors={colors} />
      </ValueWithAvatarContainer>
    );
  };

  const DisplayItemValue = (props: any) => {
    const { value, type } = props;
    if (type === 'name') {
      return <NameValue value={value} />;
    }
    return <ValueTypography fontVariant="bodyBold">{value}</ValueTypography>;
  };

  const commit = async (searchValue: any) => {
    try {
      setLoading(true);
      setCountDown(false);
      const timeIncrement = 15;
      const maxTime = 90;
      let time = 0;

      const { resolverContract, edxContract } = await getContractInstance();
      const wall = await getWalletAddress();

      const node = ethers.utils.namehash(searchValue.toString());
      const DATA = await resolverContract.interface.encodeFunctionData(
        'setAddr(bytes32,address)',
        [node, wall]
      );
      const resolverAddress = await getResolver();
      const domain = searchValue.split('.')[0].toString();

      const gasLimit = 100000; 
      const gasPrice = ethers.utils.parseUnits('30', 'gwei'); 

      const tx = await edxContract.makeCommitment(
        domain,
        wall,
        31536000 * yearsCount,
        ethers.utils.formatBytes32String(''),
        resolverAddress,
        [DATA],
        true,
        0
      );

      const tx2 = await edxContract.commit(tx, {
        gasLimit: gasLimit,
        gasPrice: gasPrice
      });


      while (time < maxTime) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        time += timeIncrement;
        setProgress(time);
      }

      await tx2.wait();

      setOpen(false);
      setCountDown(true);
      setRegisterModal(true);

      setTimeout(() => {
        setOpen(true);
        setLoading(false);
        setProgress(0);
      }, 11000);
    } catch (error) {
      console.error('Error while committing. Please try again', error);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const register = async (searchValue: any) => {
    setLoading(true);

    const { resolverContract, edxContract } = await getContractInstance();
    const wall = await getWalletAddress();
    const node = ethers.utils.namehash(searchValue);
    const DATA = await resolverContract.interface.encodeFunctionData(
      'setAddr(bytes32,address)',
      [node, wall]
    );
    const resolverAddress = await getResolver();
    const domain = searchValue.split('.')[0];
    const price = await edxContract.rentPrice(domain, 31536000 * yearsCount);
    const part = price.toString().split(',');
    const PRICE = part[0];
    //console.log('PRICE', PRICE);

    try {
      let time = 0;

      const tx3 = await edxContract.register(
        domain,
        wall,
        31536000 * yearsCount,
        ethers.utils.formatBytes32String(''),
        resolverAddress,
        [DATA],
        true,
        0,
        { value: PRICE, gasLimit: 1000000, gasPrice: 1000000000 }
      );

      setProgress(0);
      while (time < 90) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        time += 10;
        setProgress(time);
      }

      await tx3.wait();
      navigate(`/${params?.id}/successPage`);
      console.log('Registration Successful.');
    } catch (error) {
      console.log('Error occurred while registering.. Try again later', error);
    }
  };

  const EndElement = () => {
    if (progress !== 100) {
      return (
        <BarContainer data-testid="load-bar-container">
          <Bar>
            <InnerBar
              style={{ width: `${progress}%` }}
              className={progress === 100 ? 'progress-complete' : ''}
            >
              <AeroplaneIcon as={AeroplaneSVG} />
            </InnerBar>
          </Bar>
        </BarContainer>
      );
    }
    return <Spinner color="background" size="small" />;
  };

  return (
    <div className="timer">
      <Heading align="left" style={{ width: '100%', marginBottom: '1rem' }}>
        {params?.id}
      </Heading>
      <StyledCard>
        <Dialog variant="blank" open={open} onDismiss={() => setOpen(false)}>
          <Dialog.CloseButton onClick={() => setOpen(false)} />
          <InnerDialog>
            <DialogTitle>Confirm Details</DialogTitle>
            <WalletIcon as={WalletSVG} />
            {progress > 0 && EndElement()}
            <MessageTypography>
              Double check these details before confirming in your wallet.
            </MessageTypography>
            <TableContainer>
              {!countDown
                ? displayInfo?.map((props) => {
                    const { label, value, type } = props;
                    return (
                      <DisplayItemContainer
                        data-testid={`display-item-${label}-normal`}
                        key={`${label}-${value}`}
                      >
                        <DisplayItemLabel>{label}</DisplayItemLabel>
                        <DisplayItemValue {...{ value, type }} />
                      </DisplayItemContainer>
                    );
                  })
                : displayRegisterInfo?.map((props) => {
                    const { label, value, type } = props;
                    return (
                      <DisplayItemContainer
                        data-testid={`display-item-${label}-normal`}
                        key={`${label}-${value}`}
                      >
                        <DisplayItemLabel>{label}</DisplayItemLabel>
                        <DisplayItemValue {...{ value, type }} />
                      </DisplayItemContainer>
                    );
                  })}
            </TableContainer>
            {!countDown ? (
              <Dialog.Footer
                trailing={
                  <Button onClick={() => commit(params?.id)} disabled={loading}>
                    {loading ? 'Waiting for Wallet to commit' : 'Open Wallet'}
                  </Button>
                }
              />
            ) : (
              <Dialog.Footer
                trailing={
                  <Button
                    onClick={() => register(params?.id)}
                    disabled={loading}
                  >
                    {loading ? 'Waiting for Wallet to register' : 'Open Wallet'}
                  </Button>
                }
              />
            )}
          </InnerDialog>
        </Dialog>
        <Heading>Almost there</Heading>
        {countDown && <StyledCountdown countdownSeconds={10} size="large" />}
        {!countDown && (
          <StyledCountdown countdownSeconds={10} disabled={true} size="large" />
        )}
        <Typography>
          This timer helps prevent others from registering the name before you
          do. Your name is not registered until you've completed the second
          transaction.
        </Typography>
        <ButtonContainer>
          {countDown && BackButton}
          {ActionButton}
        </ButtonContainer>
      </StyledCard>
    </div>
  );
};

export default TimerPage;