import { Button, Card, Dialog, mq, Typography } from '@ensdomains/thorin';
import styled, { css } from 'styled-components';
import { ReactComponent as WalletSVG } from '../../assets/Wallet.svg';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const StyledCard = styled(Card)(
  ({ theme }) => css`
    max-width: 780px;
    margin: 0 auto;
    flex-direction: column;
    gap: ${theme.space['4']};
    padding: ${theme.space['4']};
    align-items: center;

    ${mq.sm.min(css`
      padding: ${theme.space['6']} ${theme.space['18']};
      gap: ${theme.space['6']};
    `)}
  `
);

const DialogTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.headingThree};
    font-weight: bold;
    text-align: center;
  `
);

const WalletIcon = styled.svg(
  ({ theme }) => css`
    width: ${theme.space['12']};
  `
);

const MessageTypography = styled(Typography)(
  () => css`
    text-align: center;
  `
);

const InnerDialog = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: ${theme.space.full};
    gap: ${theme.space['4']};
    max-height: 60vh;
    max-width: 100vw;
    ${mq.sm.min(css`
      width: calc(80vw - 2 * ${theme.space['6']});
      max-width: ${theme.space['128']};
    `)}
  `
);

const TableContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    width: ${theme.space.full};
    gap: ${theme.space['2']};
  `
);

const ValueTypography = styled(Typography)(
  ({ theme }) => css`
    overflow-wrap: anywhere;
    text-align: right;
    margin-left: ${theme.space['2']};
  `
);

const DisplayItemContainer = styled.div<{
  $shrink?: boolean;
  $fade?: boolean;
}>(
  ({ theme, $shrink, $fade }) => css`
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    align-items: center;
    border-radius: ${theme.radii.extraLarge};
    border: ${theme.borderWidths.px} ${theme.borderStyles.solid}
      ${theme.colors.border};
    min-height: ${theme.space['14']};
    padding: ${theme.space['2']} ${theme.space['5']};
    width: ${theme.space.full};

    ${$shrink &&
    css`
      min-height: ${theme.space['12']};
      div {
        margin-top: 0;
        align-self: center;
      }
    `}
    ${$fade &&
    css`
      opacity: 0.5;
      background-color: ${theme.colors.backgroundSecondary};
    `}
  `
);

const DisplayItemLabel = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    justify-self: flex-start;
  `
);

const DisplayItemValue = (props: any) => {
  const { value } = props;
  return <ValueTypography fontVariant="bodyBold">{value}</ValueTypography>;
};

const WrapModal = ({ open, onDismiss }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();

  const displayInfo = [
    {
      label: 'Action',
      value: 'Unwrap name',
    },
    {
      label: 'Name',
      value: params?.id,
      type: 'name',
    },
  ];

  const handleWrapStatus = () => {};

  return (
    <StyledCard>
      <Dialog variant="blank" open={open} onDismiss={onDismiss}>
        <Dialog.CloseButton onClick={onDismiss} />
        <InnerDialog>
          <DialogTitle>Confirm Details</DialogTitle>
          <WalletIcon as={WalletSVG} />
          <MessageTypography>
            Double check these details before confirming in your wallet.
          </MessageTypography>
          <TableContainer>
            {displayInfo?.map((props) => {
              const { label, value, type } = props;
              return (
                <DisplayItemContainer
                  data-testid={`display-item-${label}-normal`}
                  key={`${label}-${value}`}
                >
                  <DisplayItemLabel>{label}</DisplayItemLabel>
                  <DisplayItemValue {...{ value, type }} />
                </DisplayItemContainer>
              );
            })}
          </TableContainer>
          <Dialog.Footer
            trailing={
              <Button onClick={handleWrapStatus} disabled={loading}>
                {loading ? 'Waiting for transaction' : 'Open Wallet'}
              </Button>
            }
          />
        </InnerDialog>
      </Dialog>
    </StyledCard>
  );
};

export default WrapModal;
