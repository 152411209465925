import {
  Button,
  CrossSVG,
  Input,
  PlusSVG,
  ScrollBox,
  Typography,
  mq,
} from '@ensdomains/thorin';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const TabButtonsContainer = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.space['1.25']} ${theme.space['3']};
    padding: 0 ${theme.space['4']} 0 ${theme.space['2']};
  `,
  mq.sm.min(css`
    padding: 0 ${theme.space['2']};
  `),
]);

const TabButton = styled.button(
  ({ theme }) => css`
      position: relative;
      display: block;
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
      background: none;
      color: ${theme.colors.textTertiary}
      font-size: 1.25rem;
      transition: all 0.15s ease-in-out;
      cursor: pointer;
      font-weight: ${theme.fontWeights.bold};
  
      &:hover {
        color: ${theme.colors.textSecondary};
      }
    `
);

const TabContentsContainer = styled.div(
  () => css`
    position: relative;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `
);

const ScrollBoxDecorator = styled(ScrollBox)(
  () => css`
    height: 100%;
  `
);

const TabContentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space['3']};
    padding-right: ${theme.space['1']};
    overflow: hidden;
    flex: 1;
  `
);

const Container = styled.div(
  () => css`
    display: flex;
    align-items: flex-end;
    position: relative;
  `
);

const InputWrapper = styled.div(
  ({ theme }) => css`
    flex: 1;
    position: relative;
  `
);

const ButtonContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space['11']};
    margin-right: -${theme.space['1']};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: ${theme.space.px};
  `
);

const DeleteButton = styled.button(
  ({ theme }) => css`
    width: ${theme.space['11']};
    height: ${theme.space['11']};

    display: flex;
    justify-content: center;
    align-items: center;
  `
);

const InnerButtonWrapper = styled.div(
  ({ theme }) => css`
    width: ${theme.space['8']};
    height: ${theme.space['8']};
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease-in-out;
    cursor: pointer;

    svg {
      color: ${theme.colors.greyPrimary};
    }

    &:hover {
      background: ${theme.colors.greySurface};
      transform: translateY(-1px);
    }
  `
);

const SVGWrapper = styled.div(
  ({ theme }) => css`
    svg {
      color: ${theme.colors.greyPrimary};
    }
  `
);

const AddButtonContainer = styled.div(
  ({ theme }) => css`
    transition: all 0.3s ${theme.transitionTimingFunction.inOut};
    width: 100%;
    opacity: 1;
    visibility: visible;
  `
);
const RecordTab = ({ record, setRecord, error, setError }: any) => {
  const [newRecordBtn, setNewRecordBtn] = useState(false);

  const handleTabClick = (label: string) => {};

  const validateLabel = (label: string) => {
    if (!label) {
      return 'Label cannot be empty';
    }
    return null;
  };

  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const errorMessage = validateLabel(value);
    setError((prevError: any) => ({ ...prevError, key: errorMessage }));
    setRecord((prevRecord: any) => ({ ...prevRecord, key: value }));
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const errorMessage = validateLabel(value);
    setError((prevError: any) => ({ ...prevError, value: errorMessage }));
    setRecord((prevRecord: any) => ({ ...prevRecord, value }));
  };

  return (
    <>
      <TabButtonsContainer>
        <TabButton
          onClick={() => handleTabClick('text')}
          data-testid="text-tab"
          type="button"
        >
          Text
        </TabButton>
      </TabButtonsContainer>
      {!newRecordBtn ? (
        <AddButtonContainer>
          <Button
            prefix={
              <SVGWrapper>
                <PlusSVG />
              </SVGWrapper>
            }
            colorStyle="transparent"
            onClick={() => setNewRecordBtn(true)}
            size="medium"
            data-testid="add-record-button-button"
          >
            <Typography color="greyPrimary">Add Record</Typography>
          </Button>
        </AddButtonContainer>
      ) : (
        <TabContentsContainer>
          <ScrollBoxDecorator>
            <TabContentContainer>
              <Container data-testid="record-input">
                <InputWrapper>
                  <Input
                    data-testid="add-record-key-input"
                    label="Key"
                    value={record.key}
                    placeholder="Enter Key"
                    onChange={handleKeyChange}
                    error={error.key ?? undefined}
                  />
                  <div style={{ marginTop: '20px' }} />
                  <Input
                    data-testid="add-record-value-input"
                    label="Value"
                    value={record.value}
                    placeholder="Enter Value"
                    onChange={handleValueChange}
                    error={error.value ?? undefined}
                  />
                </InputWrapper>
                {/* <ButtonContainer>
                  <DeleteButton
                    data-testid="record-input-delete-button"
                    onClick={() => setNewRecordBtn(false)}
                    onMouseDown={(e) => e.preventDefault()}
                    type="button"
                  >
                    <InnerButtonWrapper>
                      <CrossSVG />
                    </InnerButtonWrapper>
                  </DeleteButton>
                </ButtonContainer> */}
              </Container>
            </TabContentContainer>
          </ScrollBoxDecorator>
        </TabContentsContainer>
      )}
    </>
  );
};

export default RecordTab;
