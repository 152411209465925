import { Button, Card, Helper, Typography, mq } from '@ensdomains/thorin';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getNetworkID } from '../common';

const StyledCard = styled(Card)(
  ({ theme }) => css`
    max-width: 780px;
    margin: 0 auto;
    flex-direction: column;
    gap: ${theme.space['4']};
    padding: ${theme.space['4']};
    align-items: center;

    ${mq.sm.min(css`
      padding: ${theme.space['6']} ${theme.space['18']};
      gap: ${theme.space['6']};
    `)}
  `
);

const networkParams = {
  chainId: '0x7cb',
  chainName: 'Edexa Tetnet',
  nativeCurrency: {
    name: 'EDEXA',
    symbol: 'EDX',
    decimals: 18,
  },
  rpcUrls: ['https://testnet.edexa.network/rpc'],
  blockExplorerUrls: ['https://explorer.testnet.edexa.network/'],
};

const UnsupportedNetworkPage = () => {
  const [chainId, setChainId] = useState(0);

  const fetchNetworkId = async () => {
    const networkId = await getNetworkID();
    setChainId(networkId);
  };

  
  useEffect(() => {
    const handleChainChanged = () => {
      fetchNetworkId();
      if ( chainId === 1995 ) {
        navigate('/');
      }
    };
    
    if (window.ethereum) {
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.off('chainChanged', handleChainChanged);
      }
    };
  });

  async function switchNetwork() {
    if (window.ethereum) {
      try {
        // Try to switch to the network
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: networkParams.chainId }],
        });
        console.log('Network switched successfully');
        navigate('/');
        window.location.reload();
      } catch (switchError) {
        if ((switchError as { code: number }).code === 4902) {
          try {
            // Try to add the network
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [networkParams],
            });
            console.log('Network added successfully');
            navigate('/');
          } catch (addError) {
            console.error('Failed to add network:', addError);
          }
        } else {
          console.error('Failed to switch network:', switchError);
        }
      }
    } else {
      console.error('MetaMask is not installed');
    }
  }
  const navigate = useNavigate();
  return (
    <div className="main">
      <div className="unsupported-page">
        <StyledCard>
          <Typography style={{ width: '100%' }}>
            <Helper type="error">Unsupported network</Helper>
          </Typography>
          <Button onClick={switchNetwork}>Change Network</Button>
        </StyledCard>
      </div>
    </div>
  );
};

export default UnsupportedNetworkPage;
