import { Card, Heading, Typography, mq } from '@ensdomains/thorin';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DateSelection } from '../components/DateSelection/DateSelection';
import { ONE_DAY, ONE_YEAR } from '../utils/constants/Constants';
import { RootState } from '../store/Store';
import { useSelector } from 'react-redux';

const StyledCard = styled(Card)(
  ({ theme }) => css`
    max-width: 780px;
    margin: 0 auto;
    flex-direction: column;
    gap: ${theme.space['4']};
    padding: ${theme.space['4']};

    ${mq.sm.min(css`
      padding: ${theme.space['6']} ${theme.space['18']};
      gap: ${theme.space['6']};
    `)}
  `
);

const RegisterPage = () => {
  const { registerPage } = useSelector(
    (state: RootState) => state.registerPage
  );
  const [seconds, setSeconds] = useState(ONE_YEAR);
  const minSeconds = 28 * ONE_DAY;

  const params = useParams();

  return (
    <div className="register">
      <Heading align="left" style={{ width: '100%', marginBottom: '1rem' }}>
        {/* {params?.id} */}
      </Heading>
      <StyledCard>
        {!registerPage ? (
          <Heading align="left" style={{ opacity: 0.8 }}>
            Register&nbsp;&nbsp;
            <span style={{ fontWeight: 'bold', color: 'grey' }}>
              {params?.id}
            </span>
          </Heading>
        ) : (
          <>
            <Heading align="center">Before we start</Heading>
            <Heading align="center">
              <Typography fontVariant="body">
                Registering your name takes three steps
              </Typography>
            </Heading>
          </>
        )}
        <DateSelection {...{ seconds, setSeconds, minSeconds }} />
      </StyledCard>
    </div>
  );
};

export default RegisterPage;