import {
  AeroplaneSVG,
  Button,
  Card,
  Dialog,
  Input,
  Spinner,
  Typography,
  mq,
} from '@ensdomains/thorin';
import { ReactComponent as WalletSVG } from '../../assets/Wallet.svg';
import styled, { css } from 'styled-components';
import { useState } from 'react';
import {
  getContractInstance,
  getResolver,
  getWalletAddress,
} from '../../common';
import { ethers } from 'ethers';
import { useParams } from 'react-router-dom';

const InnerDialog = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: ${theme.space.full};
    gap: ${theme.space['4']};
    max-height: 60vh;
    max-width: 100vw;
    ${mq.sm.min(css`
      width: calc(80vw - 2 * ${theme.space['6']});
      max-width: ${theme.space['128']};
    `)}
  `
);

const ParentLabel = styled.div(
  ({ theme }) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${theme.space['48']};
  `
);

const StyledCard = styled(Card)(
  ({ theme }) => css`
    max-width: 780px;
    margin: 0 auto;
    flex-direction: column;
    gap: ${theme.space['4']};
    padding: ${theme.space['4']};
    align-items: center;

    ${mq.sm.min(css`
      padding: ${theme.space['6']} ${theme.space['18']};
      gap: ${theme.space['6']};
    `)}
  `
);

const DialogTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.headingThree};
    font-weight: bold;
    text-align: center;
  `
);

const WalletIcon = styled.svg(
  ({ theme }) => css`
    width: ${theme.space['12']};
  `
);

const MessageTypography = styled(Typography)(
  () => css`
    text-align: center;
  `
);

const TableContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    width: ${theme.space.full};
    gap: ${theme.space['2']};
  `
);

const DisplayItemContainer = styled.div<{
  $shrink?: boolean;
  $fade?: boolean;
}>(
  ({ theme, $shrink, $fade }) => css`
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    align-items: center;
    border-radius: ${theme.radii.extraLarge};
    border: ${theme.borderWidths.px} ${theme.borderStyles.solid}
      ${theme.colors.border};
    min-height: ${theme.space['14']};
    padding: ${theme.space['2']} ${theme.space['5']};
    width: ${theme.space.full};

    ${$shrink &&
    css`
      min-height: ${theme.space['12']};
      div {
        margin-top: 0;
        align-self: center;
      }
    `}
    ${$fade &&
    css`
      opacity: 0.5;
      background-color: ${theme.colors.backgroundSecondary};
    `}
  `
);

const DisplayItemLabel = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    justify-self: flex-start;
  `
);

const ValueTypography = styled(Typography)(
  ({ theme }) => css`
    overflow-wrap: anywhere;
    text-align: right;
    margin-left: ${theme.space['2']};
  `
);

const BarContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space.full};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.space['2']};
  `
);

const Bar = styled.div(
  ({ theme }) => css`
    width: ${theme.space.full};
    height: ${theme.space['9']};
    border-radius: ${theme.radii.full};
    background-color: ${theme.colors.blueSurface};
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    --bar-color: ${theme.colors.blue};
  `
);

const InnerBar = styled.div(
  ({ theme }) => css`
    padding: ${theme.space['2']} ${theme.space['4']};
    height: ${theme.space['9']};

    border-radius: ${theme.radii.full};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    transition: width 1s linear;
    &.progress-complete {
      width: 100% !important;
      padding-right: ${theme.space['2']};
      transition: width 0.5s ease-in-out;
    }

    background-color: var(--bar-color);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    position: relative;

    & > svg {
      position: absolute;
      right: ${theme.space['2']};
      top: 50%;
      transform: translateY(-50%);
    }
  `
);

const AeroplaneIcon = styled.svg(
  ({ theme }) => css`
    width: ${theme.space['4']};
    height: ${theme.space['4']};
    color: ${theme.colors.background};
  `
);

const CreateSubname = ({ open, setOpen, refetch }: any) => {
  const [label, setLabel] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const params = useParams();

  const validateLabel = (label: string) => {
    if (!label) {
      return 'Label cannot be empty';
    }
    if (/[^a-z0-9-]/.test(label)) {
      return 'Label must not contain special characters';
    }
    // Condition will come whether the name exists or not
    // if (label === params?.id?.split('.')[0]) {
    //   return 'Name already exists';
    // }
    return null;
  };

  const handleSubdomainRegistration = async () => {

    try{
      let time = 0;
      setLoading(true);
  
      const { nameWrapperContract } = await getContractInstance();
      const resolver = await getResolver();
      const parentNode = ethers.utils.namehash(`${params.id}`);
      const owner = await getWalletAddress();
      const fuses = 0;
      const ttl = 0;
      const expiry = 0;
  
      const tx = await nameWrapperContract.setSubnodeRecord(
        parentNode,
        label,
        owner,
        resolver,
        ttl,
        fuses,
        expiry,{gasLimit: 1000000,gasPrice: ethers.utils.parseUnits('30', 'gwei')}
      );
  
      setProgress(0);
      while (time < 97) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        time += 10;
        setProgress(time);
      }
      await tx.wait();
  
      setModalOpen(false);
      setOpen(false);
      refetch();
    }catch(e){console.log(e)}
   
  };

  const validateName = (value: string) => {
    if (!value) {
      throw new Error('Label cannot be empty');
    }
    if (value === params.id) {
      throw new Error('Label cannot be the same as the parent name');
    }
    return value;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    try {
      const normalised = validateName(value);
      setLabel(normalised);
    } catch {
      setLabel(value);
    }
    const errorMessage = validateLabel(value);
    setError(errorMessage);
  };

  const handleNextClick = () => {
    const errorMessage = validateLabel(label);
    console.log(errorMessage);
    if (errorMessage == null) {
      setModalOpen(true);
    } else {
      setError(errorMessage);
      setModalOpen(false);
    }
  };

  const displayInfo = [
    {
      label: 'Name',
      value: params?.id,
      type: 'name',
    },
    {
      label: 'Action',
      value: 'Create subname',
    },
    {
      label: 'Subname',
      value: `${label}.${params.id}`,
    },
  ];

  const DisplayItemValue = (props: any) => {
    const { value } = props;
    return <ValueTypography fontVariant="bodyBold">{value}</ValueTypography>;
  };

  const EndElement = () => {
    if (progress !== 100) {
      return (
        <BarContainer data-testid="load-bar-container">
          <Bar>
            <InnerBar
              style={{ width: `${progress}%` }}
              className={progress === 100 ? 'progress-complete' : ''}
            >
              <AeroplaneIcon as={AeroplaneSVG} />
            </InnerBar>
          </Bar>
        </BarContainer>
      );
    }
    return <Spinner color="background" size="small" />;
  };

  return (
    <>
      <Dialog
        variant="blank"
        open={open}
        onDismiss={() => {
          setOpen();
          setError('');
        }}
      >
        <Dialog.Heading title="Create Subname" />
        <InnerDialog>
          <Input
            data-testid="add-subname-input"
            label="Label"
            suffix={<ParentLabel>.{params?.id}</ParentLabel>}
            value={label}
            onChange={handleChange}
            error={error ?? undefined}
          />
        </InnerDialog>
        <Dialog.Footer
          leading={
            <Button
              colorStyle="accentSecondary"
              onClick={() => {
                setOpen();
                setError('');
                setLabel('');
              }}
            >
              Cancel
            </Button>
          }
          trailing={
            <Button
              data-testid="create-subname-next"
              onClick={handleNextClick}
              disabled={!!error}
            >
              Next
            </Button>
          }
        />
      </Dialog>
      <StyledCard>
        <Dialog
          variant="blank"
          open={modalOpen}
          onDismiss={() => {
            setLabel('');
            setModalOpen(false);
          }}
        >
          <Dialog.CloseButton
            onClick={() => {
              setLabel('');
              setModalOpen(false);
            }}
          />
          <InnerDialog>
            <DialogTitle>Confirm Details</DialogTitle>
            <WalletIcon as={WalletSVG} />
            {progress > 0 && EndElement()}
            <MessageTypography>
              Double check these details before confirming in your wallet.
            </MessageTypography>
            <TableContainer>
              {displayInfo?.map((props) => {
                const { label, value, type } = props;
                return (
                  <DisplayItemContainer
                    data-testid={`display-item-${label}-normal`}
                    key={`${label}-${value}`}
                  >
                    <DisplayItemLabel>{label}</DisplayItemLabel>
                    <DisplayItemValue {...{ value, type }} />
                  </DisplayItemContainer>
                );
              })}
            </TableContainer>
            <Dialog.Footer
              trailing={
                <Button
                  onClick={handleSubdomainRegistration}
                  disabled={loading}
                >
                  {loading ? 'Waiting..' : 'Open Wallet'}
                </Button>
              }
            />
          </InnerDialog>
        </Dialog>
      </StyledCard>
    </>
  );
};
export default CreateSubname;
