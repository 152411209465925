import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	walletAddress: localStorage.getItem('walletAddress') ?? ''
};

const walletAddressSlice = createSlice({
	name: "walletAddress",
	initialState,
	reducers: {
		setWalletAddress: (state, action) => {
			state.walletAddress = action.payload;
		}
	}
});

export const { setWalletAddress } = walletAddressSlice.actions;

export default walletAddressSlice.reducer;
