import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { ThemeProvider } from 'styled-components';
import { ThorinGlobalStyles, lightTheme } from '@ensdomains/thorin';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/Store';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { fetchUrl } from './common';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

async function initializeApolloClient() {
  const uri = await fetchUrl();
  const client = new ApolloClient({
    uri: uri,
    cache: new InMemoryCache(),
  });

  root.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={lightTheme}>
          <ThorinGlobalStyles />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
}

initializeApolloClient();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
