import React from 'react';
import { useRoutes } from 'react-router-dom';
import { connectedRoutes } from './Routes';

const ConnectedRouter = () => {
  const connectedRoutesRender = useRoutes(connectedRoutes);
  return <>{connectedRoutesRender}</>;
};

export default ConnectedRouter;
