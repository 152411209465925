import React from 'react';
import styled, { css } from 'styled-components';
import Invoice from './Invoice';

const InvoiceContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: ${theme.space['2']};
    width: 100%;
  `
);

const FullInvoice = ({ dateInYears }: any) => {
  return (
    <InvoiceContainer>
      <Invoice dateInYears={dateInYears} />
    </InvoiceContainer>
  );
};

export default FullInvoice;
